.category-section{
  height: auto;
  background-color: white;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 0% 7%
}
.category-container{
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.category-row{
  display: flex;
  justify-content: flex-start;
  overflow: visible;
  overflow-x: scroll;
  height: 560px;
  align-items: center;
  width:100%
}
/* .category-row>div{
  overflow: visible
} */
.category-title{
  font-family: institution-bold;
  font-size: 1.5em;
  margin-bottom: 0;
  margin-top: 2em
}
.category-data{
  width: 365px;
  height: 522px;
  border-radius: 20px;
  background-color: #ffffff;
  margin-right: 2em;
  cursor:pointer;
  /* overflow: hidden; */
}
.category-data-mobile{
  display:none
}
.category-data, .category-data-mobile, .card-front, .card-back {
  -webkit-perspective: 1000px;
  perspective: 2000px;
  position: relative;
  /* margin: 0 auto; */
}
/* .category-data:hover .api-container, .category-data:hover .api-container {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
} */
.api-container {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: all 0.6s ease 0s;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
}
.card-front, .card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width:100%;
  height:100%;
  top:0;
  left:0;
  position:absolute;
  box-shadow: 0 2px 8px 0 rgba(153, 153, 153, 0.44);
  border: solid 1px #efeff7;
  border-radius: 20px;
  overflow: hidden;
}
.card-front {
  z-index: 99;
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  background: white;
}
.card-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background: white;
  padding: 5% 10%
}
.api-front-data{
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height:64%
}
.api-image{
  height:36%;
  width:100%;
  object-fit: cover;
}
.no-api-image{
  height:36%;
  width:100%;
  background-color: light-grey
}
.api-title{
  display: flex;
  align-items: center;
}
.api-icon{
  max-width: 5rem
}
.api-title>p{
  font-size: 1.25em;
  font-family: institution-bold;
  margin-left: 1em
}
.api-front-data>p{
  font-family: institution-light;
  font-size: 1rem;
  color: var(--font2);
  height: 3.8em;
  overflow: scroll;
  margin: 0
}
.api-front-data .tags{
  display:flex;
  justify-content: space-around;
  width: 100%;
  /* padding: 0 10%; */
}
.tags .stage{
  padding: 0.4em 1em;
  border-radius: 8px;
  background-color: #90c74b;
  color:white;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem
}
.tags .visibility{
  padding: 0.4em 1em;
  border-radius: 8px;
  background-color: #5a6f7b;
  color:white;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem
}
.api-back-data p{
  font-size: 0.75em;
  margin:0
}
.top-container{
  margin-top: 0.5em
}
.card-back .main-title{
  font-family: institution-bold;
  color: var(--font);
  margin: 1em 0 0 0
}
.card-back .title{
  font-family: institution;
  color: var(--font);
  margin: 0
}
.card-back .text{
  font-family: institution-light;
  color: var(--font2);
}
.flags-container{
  margin-top: 0.5em
}
.flags-container .title{
  margin-bottom: 0.5em
}
.flags{
  display:flex
}
.bottom-container{
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1em
}
.bottom-container>*{
  width:50%;
  /* border: #efeff7 solid 1px; */
}
.bottom-container .title{
  margin:0;
  margin-bottom: 0.5em
}
.certifications{
  display:flex;
  justify-content: flex-start;
  align-items: center;
}
.certifications .certification{
  background-color: var(--font2);
  padding: 0.5em 1em;
  border-radius: 15px;
  display:flex;
  justify-content: center;
  align-items: center;
  color:white;
  margin-right: 1em
}
.languages{
  border-left: none;
  padding: 3% 3% 3% 0
}
.currencies{
  border-right: none;
  padding: 3% 0 3% 3%
}
.api-back-data .register{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height:2em;
  margin-top: 1em
}
.api-back-data .register .title{
  color: var(--main-color);
  cursor:pointer;
}
.something-missing{
  color:rgba(90, 111, 123, 0.48)
}


@media only screen and (max-width: 1024px) {
  .category-section{
    height:650px
  }
  /* .category-data{
    display:none
  } */
  .category-container{
    height:100%
  }
  .category-data{
    display: block;
    width: 300px;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .category-data-mobile{
    display: block;
    min-width: 45vw;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .api-title>p{
    font-size: 1em;
    font-family: institution-bold;
    margin-left: 0.5em
  }
  .api-title>img{
    width:3em
  }
  .api-front-data>p{
    font-size:0.75rem
  }
  .tags .visibility, .tags .stage{
    padding: 0.2em 1em;
    font-size: 0.75rem
  }
}

@media only screen
and (min-device-width : 768px)
and (max-width : 1024px)
and (orientation : landscape) {
  .category-section{
    height:80vh
  }
  /* .category-data{
    display:none
  } */
  .category-container{
    height:100%
  }
  .category-data{
    display: block;
    min-width: 35vw;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .category-data-mobile{
    display: block;
    min-width: 35vw;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .api-title>p{
    font-size: 1.25em;
    font-family: institution-bold;
    margin-left: 0.5em;
  }
  .api-title>img{
    width:4em
  }
}

@media only screen and (max-width: 450px) {
  .banner>p{
    margin: 0;
    margin-bottom: 0.2em;
  }
  .category-section{
    height:540px
  }
  .category-container{
    height:100%
  }
  .category-data{
    display: block;
    width: 270px;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .category-data-mobile{
    display: block;
    min-width: 80vw;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .category-title{
    margin-top: 1em;
    font-size: 1rem
  }
  .api-front-data p{
    font-size: 0.66rem;
  }
  .api-front-data .stage, .api-front-data .visibility{
    font-size: 0.66rem;
  }
  .api-back-data p{
    font-size: 0.66em;
  }
  .card-front, .card-back{
    overflow: scroll;
  }
  .api-container-back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  .api-title>p{
    font-size: 0.75rem;
    font-family: institution-bold;
    margin-left: 0.5em
  }
  .api-title>img{
    width:3em
  }
  .flags-container img{
    width: 1.5rem !important;
    height:1.5rem !important
  }
}

@media only screen
and (max-width : 812px) and
(orientation : landscape) {
  .banner>p{
    margin: 0;
    margin-bottom: 0.2em;
  }
  .category-section{
    height:140vh
  }
  .category-container{
    height:100%
  }
  .category-row{
    height: 400px
  }
  .category-data{
    display: block;
    min-width: 40vw;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .category-data-mobile{
    display: block;
    min-width: 40vw;
    height: 90%;
    border-radius: 20px;
    background-color: #ffffff;
    margin-right: 2em;
  }
  .category-title{
    margin-top: 1em;
    font-size: 1rem
  }
  .api-front-data>p{
    font-size: 0.66rem;
  }
  .api-back-data p{
    font-size: 0.66em;
  }
  .card-front, .card-back{
    overflow: scroll;
  }
  .api-container-back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  .api-title>p{
    font-size: 1em;
    font-family: institution-bold;
    margin-left: 0.5em
  }
  .api-title>img{
    width:4em
  }
  .flags-container img{
    width: 1.5rem !important;
    height:1.5rem !important
  }
}
